<template>
  <v-container>
      <h1 class="mt-5 text-center primary--text">  جەردی مەخزەن : {{new Date().toISOString().split("T")[0]}}</h1>
            <v-row>
            
        </v-row>
        <v-row>
            <v-col>
                <v-simple-table>
                <thead>
                    <tr>
                        <td class="primary--text text-center">#</td>
                        <td class="primary--text text-center">ناوی کاڵا</td>
                        <td class="primary--text text-center">کۆدی کاڵا</td>
                        <td class="primary--text text-center">بڕ بە یەکە</td>
                        <td class="primary--text text-center">بڕ بە دانە</td>
                        <td class="primary--text text-center">سەرجەمی بڕ بە دانە</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in items" :key="i">
                        <td class=" text-center">{{i+1}}</td>
                        <td class=" text-center">{{item.item_name}}</td>
                        <td class=" text-center">{{item.item_code}}</td>
                        <td class=" text-center">{{item.qty_unit + `(${item.unit_name})`}}</td>
                        <td class=" text-center">{{item.qty_item}}</td>
                        <td class=" text-center">{{item.qty}}</td>
                    </tr>
                </tbody>
            </v-simple-table>
            </v-col>
        </v-row>
  </v-container>
</template>

<script>
export default {
    data() {
        return {
            items: []
        }
    },
    methods: {
        async getItems(){
            try {
                const {data} = await this.axios.get('report/representative/inventory')
                this.items = data
            } catch (error) {
                
            }
        }
    },
    created(){
        this.getItems()
    }
}
</script>

<style>
    tr td{
        border: 1px solid black;
        border-radius: 3%;
    }
</style>